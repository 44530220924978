/* eslint-disable no-underscore-dangle */
import React, { FC, useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';

import { COLORS } from '../../common/constants';

import './charts.scss';

// eslint-disable-next-line prefer-destructuring, @typescript-eslint/no-var-requires
const Chart = require('react-chartjs-2').Chart;

interface ChartsProps {
  datasets?: Record<string, string | string[]>[];
  labels?: string[];
}

const Charts: FC<ChartsProps> = ({ datasets, labels }) => {
  const [data, setData] = useState({ chartInstance: '' });

  const ref = useRef<any>(null);

  // useEffect(() => {
  //     ref?.current?.chartInstance?.update();
  // }, [data]);

  useEffect(() => {
    const newDatasets = datasets?.map((dataset, idx) => ({
      label: dataset.label,
      data: dataset.data,
      fill: false,
      borderColor: COLORS[idx],
      // backgroundColor: type === 'dashboard' ? 'white' : COLORS[idx],
      backgroundColor: COLORS[idx],
    }));
    const newData: any = {
      labels,
      datasets: newDatasets,
    };
    setData(newData);
  }, [datasets, labels]);

  useEffect(() => {
    Chart.pluginService.register({
      beforeDatasetsDraw(chart: typeof Chart) {
        if (chart.tooltip._active && chart.tooltip._active.length) {
          const activePoint = chart.controller.tooltip._active[0];
          const { ctx } = chart;
          const { x } = activePoint.tooltipPosition();
          const topY = chart.scales['y-axis-0'].top;
          const bottomY = chart.scales['y-axis-0'].bottom;

          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = '#9AA0B9';
          ctx.stroke();
          ctx.restore();
        }
      },
    });
  }, []);

  if (!datasets) return null;

  const customTooltips = (tooltipModel: any) => {
    let tooltipEl = document.getElementById('chartjs-tooltip');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      tooltipEl.className = 'chartjs-tooltip';
      tooltipEl.innerHTML = '<div></div>';
      // document.getElementsByClassName('charts')[0].appendChild(tooltipEl);
      document.body.appendChild(tooltipEl);
    }

    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = '0';
      // tooltipEl.remove();
      return;
    }

    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add('no-transform');
    }

    const getBody = (bodyItem: Record<string, unknown>) => bodyItem.lines;

    if (tooltipModel.body) {
      const titleLines = tooltipModel.title || [];
      const bodyLines = tooltipModel.body.map(getBody);

      let innerHtml = '';
      titleLines.forEach((title: string) => {
        innerHtml += `<div class="chartjs-tooltip__header">${title}</div>`;
      });

      bodyLines.forEach((body: Record<string, string>, i: number) => {
        const bodyItems = body[0]?.split(':');
        const tooltipColors = tooltipModel.labelColors[i];
        const style = `background:${tooltipColors.borderColor}`;
        const span = `<span class="chartjs-tooltip__marker" style="${style}"></span>`;
        innerHtml += `<div class="chartjs-tooltip__line"><div>${span}${bodyItems[0]}</div><div>${Number(
          bodyItems[1]
        ).toLocaleString()}</div></div>`;
      });

      const tableRoot = tooltipEl.querySelector('div');
      if (tableRoot) {
        tableRoot.innerHTML = innerHtml;
      }
    }

    const position = ref.current?.chartInstance.canvas.getBoundingClientRect();

    tooltipEl.style.opacity = '1';
    tooltipEl.style.position = 'absolute';

    const tooltipInterval = 25;

    if (position.width - (tooltipModel.caretX + tooltipEl.clientWidth + tooltipInterval) <= 0) {
      tooltipEl.style.left = `${Math.floor(
        position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.clientWidth + tooltipInterval)
      )}px`;
    } else {
      tooltipEl.style.left = `${Math.floor(
        position.left + window.pageXOffset + tooltipModel.caretX + tooltipInterval
      )}px`;
    }

    tooltipEl.style.top = `${Math.floor(
      position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.clientHeight / 2
    )}px`;
    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
    tooltipEl.style.fontSize = `${tooltipModel.bodyFontSize}px`;
    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
    tooltipEl.style.padding = `${tooltipModel.yPadding}px ${tooltipModel.xPadding}px`;
    tooltipEl.style.pointerEvents = 'none';
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    legend: { display: false },
    tooltips: {
      mode: 'index',
      intersect: false,
      enabled: false,
      custom: customTooltips,
    },
    scales: {
      xAxes: [{ gridLines: { display: false }, ticks: { fontSize: 10, fontColor: '#9AA0B9' } }],
      yAxes: [
        {
          ticks: {
            fontSize: 10,
            fontColor: '#9AA0B9',
            callback: (value: string) => value.toLocaleString(),
          },
        },
      ],
    },
    elements: {
      point: { radius: 4, hoverRadius: 4 },
      line: { borderWidth: 2 },
    },
  };

  return (
    <div className="charts">
      <div className="charts__chart">
        <Line options={options} data={data} ref={ref} />
      </div>
    </div>
  );
};

export default React.memo(Charts);
