import { HomeOutlined, LineChartOutlined, SelectOutlined } from '@ant-design/icons';
import { Menu, Spin } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';

import RootStore from '../../store/root-store';
import ReportStore from '../../store/report-store';
import SiteStore from '../../store/site-store';

const { SubMenu } = Menu;

const LeftMenu = observer(() => (
  <>
    <Menu
      // theme="dark"
      mode="inline"
      defaultSelectedKeys={['01']}
      // defaultOpenKeys={['sub1']}
      style={{ borderRight: 0 }}
    >
      <Menu.Item
        key="01"
        icon={<HomeOutlined />}
        className={cn({ 'ant-menu-item-selected': ReportStore.activeType === 'dashboard' })}
        onClick={() => RootStore.changeType('dashboard')}
      >
        Что нового?
      </Menu.Item>
      <SubMenu key="sub1" icon={<LineChartOutlined />} title="Отчеты" disabled>
        <Menu.Item key="11" onClick={() => RootStore.changeType('hitsfrom')}>
          Откуда идут
        </Menu.Item>
        <Menu.Item key="12">С каких доменов</Menu.Item>
        <Menu.Item key="13">Просмотры страниц</Menu.Item>
        <Menu.Item key="14">Просмотры каталогов</Menu.Item>
      </SubMenu>
    </Menu>
    <Menu
      // theme="dark"
      mode="inline"
      defaultOpenKeys={['sub2']}
    >
      <SubMenu key="sub2" icon={<SelectOutlined />} title="Выбрать сайт">
        {SiteStore.isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <Spin />
          </div>
        ) : (
          SiteStore.sites?.map((site) => (
            <Menu.Item
              className={cn({ 'ant-menu-item-selected': SiteStore.activeId === site.id })}
              key={site.id}
              onClick={() => RootStore.changeSite(site.id)}
            >
              {site.name}
            </Menu.Item>
          ))
        )}
      </SubMenu>
    </Menu>
  </>
));

export default LeftMenu;
