/* eslint-disable react/jsx-props-no-spreading */
import { LoginOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Layout } from 'antd';
// import rcFieldForm from 'rc-field-form';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { observer } from 'mobx-react-lite';

import RootStore from '../../store/root-store';

import './login.scss';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 8 },
};

const { Content } = Layout;

const Login = observer(() => {
  const onFinish = async (values: { login: string; password: string; remember: boolean }): Promise<void> => {
    await RootStore.login(values.login, values.password, values.remember);
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity<{ login: string; password: string; remember: boolean }>) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Content className="content-auth">
      <div className="content-auth__title">Вход в систему</div>
      <div className="content-auth__body">
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ width: '100%' }}
          size="large"
        >
          <Form.Item label="Логин" name="login" rules={[{ required: true, message: 'Введите логин!' }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Пароль" name="password" rules={[{ required: true, message: 'Введите пароль!' }]}>
            <Input.Password />
          </Form.Item>

          <Form.Item {...tailLayout} name="remember" valuePropName="checked">
            <Checkbox>Запомнить</Checkbox>
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button
              type="primary"
              htmlType="submit"
              block
              icon={<LoginOutlined />}
              loading={RootStore.UserStore.isLoading}
            >
              Вход
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Content>
  );
});

export default Login;
