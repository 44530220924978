import {
  DatabaseOutlined,
  DeploymentUnitOutlined,
  FileSearchOutlined,
  GlobalOutlined,
  LogoutOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { Layout, Spin } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import moment from 'moment';

import Charts from '../Charts/Charts';
import RootStore from '../../store/root-store';

import './dashboard.scss';

const { ReportStore, SiteStore, UserStore } = RootStore;

const { Content } = Layout;

const Dashboard = observer(() => (
  <Content className="content-dashboard">
    <div className="content-dashboard__title">
      <div>
        <span>Что нового на сайте {SiteStore.sites?.find((site) => site.id === SiteStore.activeId)?.name}</span>
      </div>
      <div>
        <span>На счете: {RootStore.UserStore.profile.Profiles_Account?.toLocaleString()} руб.</span>
        <LogoutOutlined style={{ color: 'red' }} onClick={() => UserStore.logout()} />
      </div>
    </div>
    <div className="content-dashboard__info">
      <DeploymentUnitOutlined style={{ color: 'rgb(143, 201, 251)' }} />
      <div className="info-block">
        <div className="info-block__title">Хиты</div>
        <div className="info-block__value">
          {ReportStore.isLoading ? <Spin /> : ReportStore.data.day?.Visits_Hits?.toLocaleString()}
        </div>
      </div>
    </div>
    <div className="content-dashboard__info">
      <DatabaseOutlined style={{ color: 'rgb(216, 151, 235)' }} />
      <div className="info-block">
        <div className="info-block__title">Хосты</div>
        <div className="info-block__value">
          {ReportStore.isLoading ? <Spin /> : ReportStore.data.day?.Visits_Hosts?.toLocaleString()}
        </div>
      </div>
    </div>
    <div className="content-dashboard__info">
      <GlobalOutlined style={{ color: 'rgb(248, 200, 46)' }} />
      <div className="info-block">
        <div className="info-block__title">С других сайтов</div>
        <div className="info-block__value">
          {ReportStore.isLoading ? <Spin /> : ReportStore.data.day?.Visits_Others?.toLocaleString()}
        </div>
      </div>
    </div>
    <div
      className={cn('content-dashboard__charts', {
        'content-dashboard__charts--centered': ReportStore.isLoading,
      })}
    >
      {ReportStore.isLoading ? (
        <Spin size="large" />
      ) : (
        <Charts
          datasets={[
            {
              label: 'Хиты',
              data: ReportStore.data.charts?.map((chart: Record<string, unknown>) => chart.Visits_Hits).reverse(),
            },
            {
              label: 'Аудитория',
              data: ReportStore.data.charts?.map((chart: Record<string, unknown>) => chart.Visits_Audit).reverse(),
            },
            {
              label: 'Уникальные',
              data: ReportStore.data.charts?.map((chart: Record<string, unknown>) => chart.Visits_Uni).reverse(),
            },
          ]}
          labels={ReportStore.data.charts
            ?.map((chart: Record<string, Date>) => moment(chart.Visits_Date).format('D MMM'))
            .reverse()}
        />
      )}
    </div>
    <div className="content-dashboard__info">
      <FileSearchOutlined style={{ color: '#aaa' }} />
      <div className="info-block">
        <div className="info-block__title">Из поисковых систем</div>
        <div className="info-block__value">
          {ReportStore.isLoading ? <Spin /> : ReportStore.data.day?.Visits_SE?.toLocaleString()}
        </div>
      </div>
    </div>
    <div className="content-dashboard__info">
      <UserSwitchOutlined style={{ color: 'rgb(246, 152, 153)' }} />
      <div className="info-block">
        <div className="info-block__title">Аудитория</div>
        <div className="info-block__value">
          {ReportStore.isLoading ? <Spin /> : ReportStore.data.day?.Visits_Audit?.toLocaleString()}
        </div>
      </div>
    </div>
    <div className="content-dashboard__info">
      <UserOutlined style={{ color: 'rgb(100 235 145 / 1)' }} />
      <div className="info-block">
        <div className="info-block__title">Уникальные</div>
        <div className="info-block__value">
          {ReportStore.isLoading ? <Spin /> : ReportStore.data.day?.Visits_Uni?.toLocaleString()}
        </div>
      </div>
    </div>
  </Content>
));

export default Dashboard;
