import { makeAutoObservable } from 'mobx';
import request from '../api/api';

class SiteStore {
  activeId = '0';

  sites: Record<string, string>[] = [];

  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadSites = async () => {
    try {
      this.isLoading = true;
      try {
        const sites = await request<Record<string, string>[]>('/sites', 'GET');
        this.sites = sites;
        this.activeId = sites?.[0].id;
      } catch (error) {
        console.log(error);
      }
    } finally {
      this.isLoading = false;
    }
  };

  changeSite = (id: string) => {
    this.activeId = id;
    // this.loadSites(token);
  };
}

export default new SiteStore();
