import { makeAutoObservable } from 'mobx';
import { Moment } from 'moment';

import ReportStore from './report-store';
import SiteStore from './site-store';
import UserStore from './user-store';

class RootStore {
  ReportStore = ReportStore;

  SiteStore = SiteStore;

  UserStore = UserStore;

  constructor() {
    makeAutoObservable(this);
    this.login();
  }

  login = async (login = '', password = '', remember = true): Promise<void> => {
    await this.UserStore.login(login, password, remember);
    if (this.UserStore.token && this.SiteStore.sites.length === 0) {
      await this.SiteStore.loadSites();
      await this.ReportStore.load(this.SiteStore.activeId);
    }
  };

  logout = (): void => {
    this.UserStore.logout();
  };

  changeSite = async (id: string): Promise<void> => {
    this.SiteStore.changeSite(id);
    await this.ReportStore.load(this.SiteStore.activeId);
  };

  changeType = async (type: string): Promise<void> => {
    this.ReportStore.changeType(type);
    await this.ReportStore.load(this.SiteStore.activeId);
  };

  changeRange = async (dates: Moment[]): Promise<void> => {
    if (this.ReportStore.changeRange(dates)) {
      await this.ReportStore.load(this.SiteStore.activeId);
    }
  };
}

export default new RootStore();
