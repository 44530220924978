import { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';

import ReportStore from '../store/report-store';
import Dashboard from '../components/Dashboard/Dashboard';
import TableReport from '../components/TableReport/TableReport';

const Report = observer(
  (): ReactElement => {
    switch (ReportStore.activeType) {
      case 'dashboard':
        return <Dashboard />;
      case 'hitsfrom':
        return <TableReport />;
      default:
        return <div>dd</div>;
    }
  }
);

export default Report;
