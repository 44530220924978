export const INITIAL_SELECTED_METRICS = ['profit', 'cost'];
export const COLORS = [
  '#8fc9fb',
  '#f69899',
  '#64eb91',
  '#78C58D',
  '#9AA0B9',
  '#BB6BD9',
  '#1EAEFF',
  'gray',
  '#F59A2F',
  '#F06D8D',
  '#6B81DD',
  '#00B031',
  'red',
  '#78C58D',
  '#FF8B20',
  'red',
  'green',
  'brown',
  'gray',
  'blue',
  'orange',
];
