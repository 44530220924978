import { makeAutoObservable } from 'mobx';
import moment, { Moment } from 'moment';
import request from '../api/api';

type ReportType = { charts: Record<string, unknown | string>[]; day: Record<string, unknown> };
class ReportStore {
  activeType = 'dashboard';

  range: Moment[] = [moment(), moment()];

  // data: Record<string, string> = {};
  // data: ReportType = { charts: [], day: {} };
  data: any = {};

  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  changeType = async (type: string) => {
    this.activeType = type;
  };

  changeRange = (dates: Moment[]) => {
    if (dates && dates[0] && dates[1] && (this.range[0] !== dates[0] || this.range[1] !== dates[1])) {
      this.range = dates;
      return true;
    }
    return false;
  };

  load = async (siteId: string) => {
    try {
      this.isLoading = true;
      const data = await request<ReportType>(
        `/reports/?${new URLSearchParams({
          type: this.activeType,
          siteId,
          date1: this.range[0].format('YYYY-MM-DD'),
          date2: this.range[1].format('YYYY-MM-DD'),
        })}`,
        'GET'
      );
      if (data) {
        this.data = data;
      }
    } finally {
      this.isLoading = false;
    }
  };
}

export default new ReportStore();
