import { ReactElement } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Login from './pages/Login/Login';
import Report from './pages/Report';

const useRoutes = (isAuthenticated: boolean): ReactElement => {
  if (isAuthenticated) {
    return (
      <Switch>
        <Route path="/" exact>
          <Report />
        </Route>
        <Redirect to="/" />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path="/" exact>
        <Login />
      </Route>
      <Redirect to="/" />
    </Switch>
  );
};

export default useRoutes;
