import { message } from 'antd';

const logout = () => {
  localStorage.removeItem('token');
  sessionStorage.removeItem('token');
};

export const getAccessToken = (): string => localStorage.getItem('token') || sessionStorage.getItem('token') || '';

const request = async <T>(url: string, method = 'GET', initialBody?: unknown, initialHeaders = {}): Promise<T> => {
  try {
    let body = null;
    let headers = initialHeaders;
    const token = getAccessToken();
    if (token) {
      headers = { ...headers, Authorization: `Bearer ${token}` };
    }
    if (initialBody) {
      body = JSON.stringify(initialBody);
      headers = { ...headers, 'Content-Type': 'application/json' };
    }
    const response = await fetch(`https://db.inetlog.ru${url}`, {
      method,
      body,
      headers,
    });
    if (response.status === 401) {
      logout();
    }
    const data = await response.json();

    if (!response.ok) {
      message.error(data.message);
      throw new Error(response.statusText);
    }
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export default request;
