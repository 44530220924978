import React from 'react';
import { Layout, DatePicker } from 'antd';
import { observer } from 'mobx-react-lite';
import locale from 'antd/es/date-picker/locale/ru_RU';
import moment from 'moment';

import ReportStore from '../../store/report-store';
import RootStore from '../../store/root-store';

import './table-report.scss';

const { RangePicker } = DatePicker;

const { Content } = Layout;

const TableReport = () => {
  return (
    <Content className="table-report">
      <div className="table-report__title">
        <div>Report title</div>
        <RangePicker
          locale={locale}
          value={[moment(ReportStore.range[0]), moment(ReportStore.range[1])]}
          onChange={(dates) => RootStore.changeRange([moment(dates?.[0]), moment(dates?.[1])])}
        />
      </div>
      <div>ttt</div>
    </Content>
  );
};

export default observer(TableReport);
