import React from 'react';
import { Layout } from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import 'moment/locale/ru';

import { BrowserRouter as Router } from 'react-router-dom';
import LeftMenu from './components/LeftMenu/LeftMenu';
import Logo from './logo.svg';
import useRoutes from './routes';
import UserStore from './store/user-store';

const { Sider } = Layout;

const App = observer(() => {
  const isAuthenticated = !!UserStore.token;
  const routes = useRoutes(isAuthenticated);
  moment.locale('ru');

  return (
    <Router>
      <Layout className="site-layout">
        <Sider width={220} className="site-layout-background">
          <div className="logo">
            <img src={Logo} alt="Logo" />
          </div>
          {isAuthenticated && <LeftMenu />}
        </Sider>
        <Layout>{routes}</Layout>
      </Layout>
    </Router>
  );
});

export default App;
